<template>
  <SelectDropdown :placeholder="placeholder">
    <div class="row features">
      <div class="col-12">
        <div class="row feature-select-header pl-1 py-2">
          <div class="col text-left">Select 1 or more feature(s) that matter to you (in order of importance)</div>
        </div>
        <div class="list-group flex-row flex-wrap">
          <div class="col-6 col-lg-4 pl-0 pr-1" v-for="feature in features" :key="feature.name">
            <div class="row text-left">
              <div class="col-12">
                <box-check v-model="feature.selected" :label="feature.name" @input="toggleFeature(feature)">
                  <span class="rank" v-show="feature.rank > 0">{{
                    toOrdinal(feature.rank)
                  }}</span>
                </box-check>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SelectDropdown>
</template>

<script>
import common from "Mixins/common";
const SelectDropdown = () => import("Components/SelectDropdown");
const BoxCheck = () => import("Components/BoxCheck");

export default {
  props: {
    features: Array,
    value: Array,
  },
  mounted() {
    this.$emit("input", this.selectedFeatures);
  },
  methods: {
    toggleFeature: function (feature) {
      if (feature.selected === true) {
        const nextRank = this.selectedFeatures.length;
        feature.rank = nextRank;
      } else {
        this.selectedFeatures.forEach((x) => {
          x.rank = (x.rank - (x.rank > feature.rank ? 1 : 0));
        });
        feature.rank = 0;
      }
      this.$emit("input", this.selectedFeatures);
    },
    checkId(index) {
      return "check" + index;
    },
  },
  computed: {
    selectedFeatures() {
      return this.features.filter((x) => x.selected);
    },
    placeholder() {
      if (this.selectedFeatures.length === 1) {
        return this.selectedFeatures[0].name;
      }
      return this.selectedFeatures.length > 0
        ? this.selectedFeatures.length + " selected"
        : "Select features";
    }
  },
  mixins: [common],
  components: { BoxCheck, SelectDropdown },
};
</script>

<style lang="scss">
.box-check {
  margin-bottom: 6px;
}

.rank {
  color: white;
  position: relative;
}

.features {
  width: 555px;
  font-size: 0.9375rem;
  color: #333333;

  @include media-breakpoint-down(sm) {
    width: 100%;
    padding-right: 15px;
  }
}

.feature-select-header {
  font-size: 0.875rem;
}
</style>
